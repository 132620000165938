import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import CloudNativeContainer from '../containers/CloudNativeContainer'

const CloudNative = (props) => (
  <Layout 
  pageInfo={{ pageName: "Cloud Native" }} 
  styleClass="home-page" 
  location={'/cloudnative/'}>
    <SEO title="Cloud Native" />
    <CloudNativeContainer />
  </Layout>
)

export default CloudNative
