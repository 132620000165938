import React, { Component } from 'react'
import BannerImage from '../components/elements/BannerImage'
import banner from '../images/slicing/service/banner.png'
import CaseStudyComponent from '../components/services/CaseStudyComponent'
import TestimonialComponent from '../components/services/TestimonialComponent'
import { firstParaCloudNative, offeringsCloudNative } from '../staticdata/legacyModernization'
import { Col, Container, Row } from 'react-bootstrap'
import ServicesNewComponent from '../components/services/ServicesNewComponent'
class CloudNativeContainer extends Component {
    render() {
        return (
            <>
                <BannerImage
                    activeTab="Cloud Native"
                    bannerImage={banner}
                    text="Cloud Native Application Development & Migration. Be relevant & ready for the Digital age by adopting cloud and build reliable, scalable platforms, applications." />
                 <Container className=" pt-3 pb-5">
                    {firstParaCloudNative && firstParaCloudNative.map((para, index) => (
                        <Row className="pt-2 px-3" key={`paracloud_${index}`}>
                            <Col>
                                <p className="initial-paragrapgh">
                                    {para}
                                </p>
                            </Col>
                        </Row>
                    ))}
                </Container>
                <ServicesNewComponent
                    heading="Offerings & solutions"
                    height="300px"
                    bgColor='#ffffff'
                    page="services"
                    data={offeringsCloudNative} />
                <CaseStudyComponent/>
                <TestimonialComponent/>
            </>
        )
    }
}

export default CloudNativeContainer